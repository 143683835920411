import React from 'react'
import "../styles/homebanner.css"
import "../styles/eachsearch.css"
import banner from "../assets/terminal.jpg"

export default function EachSearch (props) {
  return (
    <div className='search_col_bar_two'>
        <div className='search_col_small'>
            <p>{props.airport}</p>
        </div>
        <div className='search_col_small'>
            <p>{props.country}</p>
        </div>
        <div className='search_col_small'>
            <p>{props.summer}</p>
        </div>
        <div className='search_col_small'>
            <p>{props.winter}</p>
        </div>
        <div className='search_col_small_m'>
            <p></p>
        </div>
        <div className='search_col_small_s' onClick={props.viewFunction}>
            <a className="each_search" >View</a>
        </div>
        <div className='search_col_small_s' onClick={props.editFunction}>
            <a className="each_search" >Edit</a>
        </div>
        <div className='search_col_small_s' onClick={props.deleteFunction}>
            <a  className="each_search" >Delete</a>
        </div>
    </div>
  )
}
