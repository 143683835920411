import React from 'react'
import { useState} from "react";
import Papa from 'papaparse';
import "../styles/ptslots.css"
import "../styles/planneddiv.css"

export default function PlannedDiv() {

    const [sections, setSections] = useState([false, false])
    const resultArray = [];
    const airportsResult = [];

    let upload_btn = () =>{
        if (values == false) {
            window.alert("Upload Delays Report");
            return 0
        } 
        setSections([true, false])
    }

    const [parsedData, setParsedData] = useState([]);

    //State to store table Column name
    const [tableRows, setTableRows] = useState([]);
  
    //State to store the values
    const [values, setValues] = useState(false);


    const changeHandler = (event) => {
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
              const rowsArray = [];
              const valuesArray = [];
      
              // Iterating data to get column name and their values
              results.data.map((d) => {
                rowsArray.push(Object.keys(d));
                valuesArray.push(Object.values(d));
              });
      
              // Parsed Data Response in array format
              setParsedData(results.data);
      
              // Filtered Column Names
              setTableRows(rowsArray[0]);
      
              // Filtered Values
              setValues(valuesArray);
            },
          });
    };

    let parsedDataFiltered
    parsedDataFiltered = parsedData.slice(6)
    parsedDataFiltered.pop()



    const [checkedItems, setCheckedItems] = useState(
        Array(parsedDataFiltered.length).fill(false)
      );
    

    const handleCheckboxChange = (index, checked) => {
        if (checked) {
          // If the checkbox is checked, add the index to the state
          setCheckedItems(prevItems => [...prevItems, index]);
        } else {
          // If the checkbox is unchecked, remove the index from the state
          setCheckedItems(prevItems => prevItems.filter(item => item !== index));
        }
    }

    let eachId = (id) =>{
        let arrInputData = eachDataParser(id);
    

        console.log(arrInputData)
    } 

    let eachDeptSlots = (inputArr) =>{
        let sameDeptAirport;

        if(inputArr[1] == inputArr[2]){
            sameDeptAirport = true;
        } else {
            sameDeptAirport = false
        }
    }


    let createScrNewSlot = (inputArr, type) =>{
        let dateFormat = Date.parse(inputArr[7])
        
        let result = ""
    }

    let eachDataParser = (id) =>{
        let input = parsedDataFiltered[id];
        let eachFlightNo = "FR" + input["__parsed_extra"][0];
        let eachDepPlan = input["__parsed_extra"][1];
        let eachDepActual = input["__parsed_extra"][2];
        let eachSTD = input["__parsed_extra"][3];
        let eachSTA = input["__parsed_extra"][6];
        let eachArrPlan = input["__parsed_extra"][7];
        let eachArrActual = input["__parsed_extra"][8];
        let eachDate = input["R Y A N A I R"]

        let arrInputData = [eachFlightNo, eachDepPlan, eachDepActual, eachSTD, eachSTA, eachArrPlan, eachArrActual, eachDate];

        return arrInputData
    }


    let handleSubmit = (event) => {
        const d = new Date();
        let str= "New SCRs created at " + d.getHours().toString() + ":" + d.getMinutes().toString() + ":" + d.getSeconds().toString()
        window.alert(str);
		event.preventDefault();

        setSections([true,true])

        checkedItems.sort( function( a , b){
            if(a > b) return 1;
            if(a < b) return -1;
            return 0;
        });

        for (let i = 0; i < checkedItems.length; i++) {
            eachId(checkedItems[i])
        }
        
        //console.log(checkedItems)
	}

    return (
        <div className='booking_back_container'>
            <div className='booking_top_container'>
                <div className='top_top_container'>
                <div className='booking_text_container'>
						<div className='booking_req_container'>
							<h2 className='req_slots_title'>Step 1 - Upload Files</h2>
						</div>
					</div>
                <div>
                    <div className='scr_line'>
                        <div className='scr_line_one'>
                            <p>Upload Diversions Report</p>
                        </div>
                        <input
                        type="file"
                        name="file"
                        accept=".csv"
                        onChange={changeHandler}
                        className="scr_line_two"
                        />
                    </div>
                </div>
                <div className='button-section'>
                    <button className='button_active' onClick={() => upload_btn()}>Upload</button>
                </div>
            </div>
            {sections[0]? 
            (
            <div className='bot_bot_container'>
                <div className='booking_text_container'>
                    <div className='booking_req_container'>
                        <h2 className='req_slots_title'>Step 2 - Select the flights</h2>
                    </div> 
                </div>
            <div className='planned-table-container'>
                <div className="planned-sub-cont-1">
                    <label>Select</label>
                </div>
                <div className="planned-sub-cont-2">
                    <label>Flight Number</label>
                </div>
                <div className="planned-sub-cont-1">
                    <label>DEP Plan</label>
                </div>
                <div className="planned-sub-cont-1">
                    <label>DEP Actual</label>
                </div>
                <div className="planned-sub-cont-1">
                    <label>STD</label>
                </div>
                <div className="planned-sub-cont-1">
                    <label>STA</label>
                </div>
                <div className="planned-sub-cont-1">
                    <label>ARR Plan</label>
                </div>
                <div className="planned-sub-cont-1">
                    <label>ARR Actual</label>
                </div>
                <div className="planned-sub-cont-1">
                    <label>Date</label>
                </div>
            </div>
            {parsedDataFiltered.map((item, index) => (
                <div className='planned-table-container'>
                    <div className="planned-sub-cont-1">
                        <input
                        type="checkbox"
                        checked={checkedItems.includes(index)}
                        onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                        />
                    </div>
                    <div className="planned-sub-cont-2">
                        <label>FR{parsedDataFiltered[index]["__parsed_extra"][0]}</label>
                    </div>
                    <div className="planned-sub-cont-1">
                        <label>{parsedDataFiltered[index]["__parsed_extra"][1]}</label>
                    </div>
                    <div className="planned-sub-cont-1">
                        <label>{parsedDataFiltered[index]["__parsed_extra"][2]}</label>
                    </div>
                    <div className="planned-sub-cont-1">
                        <label>{parsedDataFiltered[index]["__parsed_extra"][3]}</label>
                    </div>
                    <div className="planned-sub-cont-1">
                        <label>{parsedDataFiltered[index]["__parsed_extra"][6]}</label>
                    </div>
                    <div className="planned-sub-cont-1">
                        <label>{parsedDataFiltered[index]["__parsed_extra"][7]}</label>
                    </div>
                    <div className="planned-sub-cont-1">
                        <label>{parsedDataFiltered[index]["__parsed_extra"][8]}</label>
                    </div>
                    <div className="planned-sub-cont-1">
                        <label>{parsedDataFiltered[index]["R Y A N A I R"]}</label>
                    </div>
                </div>
                
            ))}
            <div className="button-section">
				{
					checkedItems.length?
					<button className="button_active" onClick={handleSubmit}>Genererate SCR's</button>
					: null
				}
				</div>
            </div>
            ) : 
            (
                <div></div>
            )
            } 
            {
                sections[1]?  (
                <div className='bot_bot_container'>
                    <div className='booking_text_container'>
						<div className='booking_req_container'>
							<h2 className='req_slots_title'>Step 3 - Send SCR's</h2>
						</div>
					</div>
                </div>
                ) : (
                    <div>

                    </div>
                )
            }
            
            
            
        


             {/* FOR DEBUGGING ONLY
            <div>
                <button onClick={() => {console.log(parsedData[6])}}>Parsed Data</button>
                <button onClick={() => {console.log(tableRows[6])}}>Table Rows</button>
                <button onClick={() => {console.log(values)}}>Values Array</button>
            </div>
            */}
            
            {/*  FOR DEBUGGING ONLY
                <div>
                    <button onClick={() => {console.log(parsedDelay)}}>Parsed Data</button>
                    <button onClick={() => {console.log(delaysRows)}}>Table Rows</button>
                    <button onClick={() => {console.log(delaysValue)}}>Values Array</button>
                </div>
            */}
            </div>
        </div>  
    )
}
