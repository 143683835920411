import React from 'react'
import { useState } from "react";
import Papa from 'papaparse';
import "../styles/ptslots.css"

export default function PTSlots() {
    
    let addSlot = () => {
		setFormValues([...formValues, {request_type: "N", flight_number: "", date:"", time:"",slot_type: "", reg: ""}])
	}

    const [sections, setSections] = useState([false, false])

    const [formValues, setFormValues] = useState(
		[{ request_type: "", flight_number: "", date: "", time:"", slot_type: "", reg: ""}])

    let iata_season = "S24"
    // State to store parsed data
    const [parsedData, setParsedData] = useState([]);
    const [parsedDelay, setParsedDelays] = useState([]);

    //State to store table Column name
    const [tableRows, setTableRows] = useState([]);
    const [delaysRows, setDelaysRows] = useState([]);
  
    //State to store the values
    const [values, setValues] = useState(false);
    const [delaysValue, setDelaysValues] = useState(false);

    const changeDelaysHandler = (event) => {
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
              const rowsArray = [];
              const valuesArray = [];
      
              // Iterating data to get column name and their values
              results.data.map((d) => {
                rowsArray.push(Object.keys(d));
                valuesArray.push(Object.values(d));
              });
      
              // Parsed Data Response in array format
              setParsedDelays(results.data);
      
              // Filtered Column Names
              setDelaysRows(rowsArray[0]);
      
              // Filtered Values
              setDelaysValues(valuesArray);
            },
          });
    }
    
    const changeHandler = (event) => {
        // Passing file data (event.target.files[0]) to parse using Papa.parse
        Papa.parse(event.target.files[0], {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            const rowsArray = [];
            const valuesArray = [];
    
            // Iterating data to get column name and their values
            results.data.map((d) => {
              rowsArray.push(Object.keys(d));
              valuesArray.push(Object.values(d));
            });
    
            // Parsed Data Response in array format
            setParsedData(results.data);
    
            // Filtered Column Names
            setTableRows(rowsArray[0]);
    
            // Filtered Values
            setValues(valuesArray);
          },
        });
    };

    let handleChange = (i, e) => {
		let newFormValues = [...formValues];
		newFormValues[i][e.target.name] = e.target.value.toUpperCase();
		setFormValues(newFormValues);
	}

    let removeFormFields = (i) => {
		let newFormValues = [...formValues];
		newFormValues.splice(i, 1);
		setFormValues(newFormValues)
	}

	let removeFormFieldsC = (i) => {
		let newFormValues = [...formValues];
		newFormValues.splice(i, 2);
		setFormValues(newFormValues)
	}

    let todaysDate = () =>{
		let monthsList = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
		const date = new Date();
		let month_no = date.getUTCMonth();
		let month = monthsList[month_no]
		let day = date.getUTCDate().toString();
		if(day.length === 1){
			day = "0" + day
		}
		let result = day + month
		return result
	}

    let handleSubmit = (event) => {
        const d = new Date();
        let str= "New SCRs created at " + d.getHours().toString() + ":" + d.getMinutes().toString() + ":" + d.getSeconds().toString()
        window.alert(str);
		event.preventDefault();

        //Print form values
        console.log(formValues);

        //flights array
        setFlights(addFlights());
        setSections([true,true])
	}

    let addFlights = () =>{
        let result = []
        for (let i = 0; i < formValues.length; i++){
            result.push(formValues[i]["flight_number"])
        }
        return result
    }


    //Sectiont to generate SCRs and Spreadsheet line

    let donwloadEmail = (index) =>{
        let scr = generateScr(index)
        let subject = generateSubject(index)
        let emails = sendEmails(index)
        let cc = "slotdesk@ryanair.com;schedule@ryanair.com;ohanlons@ryanair.com;flightplanner@ryanair.com"
        generateDelays(index)
        window.open(`mailto:${emails}?cc=${cc}&subject=${subject}&body=${scr}`);
    }

    let copyToClipboard = (index) =>{
        let lineFormValues = formValues[index]
        let csvLineValues = values[findFlight(lineFormValues["flight_number"])]

        let flightNoClipboard = lineFormValues["flight_number"]
        let serviceTypeClipboard = "J"
        let oAirportClipboard = csvLineValues[1][3];
        let dAirportClipboard = csvLineValues[1][4];
        let routeClipboard = `${oAirportClipboard}${dAirportClipboard}`;
        let aircraftRegClipboard = lineFormValues["reg"];
        let etdClibpboard = csvLineValues[1][0]
        let etaClipboard = csvLineValues[1][6]
        let msgClipboard = `PT Night Slot. Requested for ${lineFormValues["time"]}`
        let dateClipboard = new Date().toLocaleDateString()

        const text = `${flightNoClipboard}	${serviceTypeClipboard}	${aircraftRegClipboard}	${routeClipboard}	${etdClibpboard}	${etaClipboard}	 	 	${msgClipboard}		 	Y	Y`
        
        navigator.clipboard.writeText(text)
    }

    let generateDelays = (index) =>{
        let delayInitial = "AC DELAYED DUE TO "
        let delayList = ""
        let aircraft_reg = formValues[index]["reg"]
        let indexList = findDelays(aircraft_reg);

        for (let j = 0; j < indexList.length; j++){
            if(delaysValue[indexList[j]][0] == "19") {
                delayList = delayList + "FR" + delaysValue[indexList[j]][1][8] + " - PRM ASSISTANCE / "
            } else if (delaysValue[indexList[j]][0] == "35") {
                delayList = delayList + "FR" + delaysValue[indexList[j]][1][8] + " - AIRCRAFT CLEANING DUE TO SICK PAX / "
            } else if (delaysValue[indexList[j]][0] == "41") {
                delayList = delayList + "FR" + delaysValue[indexList[j]][1][8] + " - AIRCRAFT DEFECTS / "
            } else if ((delaysValue[indexList[j]][0] == "71") || (delaysValue[indexList[j]][0] == "72") || (delaysValue[indexList[j]][0] == "73")){
                delayList = delayList + "FR" + delaysValue[indexList[j]][1][8] + " - WEATHER RESTRICTIONS - BELOW OPERATING LIMITS / "
            } else if (delaysValue[indexList[j]][0] == "75") {
                delayList = delayList + "FR" + delaysValue[indexList[j]][1][8] + " - AIRCRAFT DE-ICING / "
            } else if (delaysValue[indexList[j]][0] == "76") {
                delayList = delayList + "FR" + delaysValue[indexList[j]][1][8] + " - REMOVAL OF AIRPORT SNOW / "
            } else if (delaysValue[indexList[j]][0] == "77") {
                delayList = delayList + "FR" + delaysValue[indexList[j]][1][8] + " - GROUND HANDLING IMPAIRED BY ADVERSE WEATHER CONDITIONS / "
            }  else if ((delaysValue[indexList[j]][0] == "81") || (delaysValue[indexList[j]][0] == "82") || (delaysValue[indexList[j]][0] == "83") || (delaysValue[indexList[j]][0] == "84")){
                delayList = delayList + "FR" + delaysValue[indexList[j]][1][8] + " - ATC RESTRICTIONS / "
            }  else if (delaysValue[indexList[j]][0] == "85") {
                delayList = delayList + "FR" + delaysValue[indexList[j]][1][8] + " - SECURITY ISSUES / "
            } else if (delaysValue[indexList[j]][0] == "86") {
                delayList = delayList + "FR" + delaysValue[indexList[j]][1][8] + " - IMMIGRATION ISSUES / "
            } else if (delaysValue[indexList[j]][0] == "87") {
                delayList = delayList + "FR" + delaysValue[indexList[j]][1][8] + " - ISSUES WITH AIRPORT FACILITIES / "
            } else if ((delaysValue[indexList[j]][0] == "88") || (delaysValue[indexList[j]][0] == "89")){
                delayList = delayList + "FR" + delaysValue[indexList[j]][1][8] + " - AIRPORT RESTRICTIONS - RAMP CONGESTION / "
            } else if ((delaysValue[indexList[j]][0] == "97") || (delaysValue[indexList[j]][0] == "98")){
                delayList = delayList + "FR" + delaysValue[indexList[j]][1][8] + " - INDUSTRIAL ACTION / "
            }

            if (delayList.length == 0){
                delayList = "OPERATIONAL ISSUES"
            }
        }
        console.log(delayInitial + delayList)
        return delayInitial + delayList
    }

    //IATA DELAY CODES
    //->Check other file delay_codes-js

    let findDelays = (reg) => {
        let result = [];

        for (let i = 8; i < delaysValue.length; i++){
            if((delaysValue[i][1][7] == reg.toUpperCase() )&& (delaysValue[i][0]!= "93")){
                result.push(i)
            }
        }
        return result
    }


    let generateSubject = (index) =>{
        let lineFormValues = formValues[index]
        let csvLineValues = values[findFlight(lineFormValues["flight_number"])]

        let originAirport, arrivalAirport, requestSlotType, requestedSubject, flightNumber, result

        originAirport = csvLineValues[1][3];
        arrivalAirport = csvLineValues[1][4];
        requestSlotType = lineFormValues["slot_type"]
        flightNumber = lineFormValues["flight_number"]

        let airportRequest = (oAirport, aAirport, type)  =>{
            if (type == "ARRIVAL") {
                return aAirport;
            } else if (type = "DEPARTURE") {
                return oAirport;
            } else {
                return false
            }  
        }
        requestedSubject = airportRequest(originAirport, arrivalAirport, requestSlotType);

        result = `NIGHT SLOT CHG REQ ${requestedSubject} ${flightNumber}`
        return result
    }

    let sendEmails = (index) => {
        let ptAirports = ["FAO", "OPO", "LIS", "PDL", "FNC"]
        let lineFormValues = formValues[index];
        let csvLineValues = values[findFlight(lineFormValues["flight_number"])];
        let result;
        let originAirport, arrivalAirport, requestSlotType, requestedSubject, flightNumber

        originAirport = csvLineValues[1][3];
        arrivalAirport = csvLineValues[1][4];
        requestSlotType = lineFormValues["slot_type"]
        flightNumber = lineFormValues["flight_number"]

        let airportRequest = (oAirport, aAirport, type)  =>{
            if (type == "ARRIVAL") {
                return aAirport;
            } else if (type = "DEPARTURE") {
                return oAirport;
            } else {
                return false
            }  
        }
        requestedSubject = airportRequest(originAirport, arrivalAirport, requestSlotType);

        result = "slotcoordination@nav.pt;"
        if (requestedSubject == ptAirports[0]) {
            result = result + "FAOKAXH@ana.pt;";
        } else if (requestedSubject == ptAirports[1]){
            result = result + "OPOKAXH@ana.pt;";
        } else if (requestedSubject == ptAirports[2]){
            result = result + "LISANXH@ana.pt;LISCSXH@ANA.PT;"
        } else if (requestedSubject == ptAirports[3]){
            result = result + "PDLKAXH@ana.pt;"
        } else if  (requestedSubject == ptAirports[4]) {
            result = result + "fnckaxh@ana.pt;"
        }

        return result
    }

    let upload_btn = () =>{
        if (values == false) {
            window.alert("Upload Callsigns Report");
            return 0
        } 
        if (delaysValue == false) {
            window.alert("Upload Delays Report")
            return 0
        }
        setSections([true, false])
    }

    let generateScr = (index) => {
        //importing data elements
        let lineFormValues = formValues[index]
        let csvLineValues = values[findFlight(lineFormValues["flight_number"])]

        //helper for dates and times
        const [Dmonth, Dday, Dyear] = csvLineValues[0].split('/');

        //formats times and dates
        let timeFormat = (str) =>{
            let result = "";

            for (let i = 0; i < str.length; i++){
                if(str[i] != ":") {
                    result = result + str[i]
                }
            }
            return result
        }

        let dateFormat = (str) => {
            let day, month, year, result
            
            year = str[6] + str[7] + str[8] + str[9];
            month = str[3] + str[4]
            day = str[0] + str[1]

            result = year + "-" + month + "-" + day
            return new Date(result)
        }

        let setArrivalDate = (etdStr, etaStr, departureDate) => {
            const etdInt = parseInt(etdStr);
            const etaInt = parseInt(etaStr);

            if (etaInt < etdInt) {
                return new Date (departureDate.getTime() + 86400000);
            } else {
                return departureDate;
            }
        }

        let airportRequest = (oAirport, aAirport, type)  =>{
            if (type == "ARRIVAL") {
                return aAirport;
            } else if (type = "DEPARTURE") {
                return oAirport;
            } else {
                return false
            }
        }

        //declare final values
        let  originAirport, arrivalAirport, departureDate, arrivalDate, etd, eta
        let requestSlotType, flightNumber, requestedDate, requestedTime, aircraftReg, requestedAirport

        //formatted data to print result scr
        originAirport = csvLineValues[1][3];
        arrivalAirport = csvLineValues[1][4];
        etd = timeFormat(csvLineValues[1][0])
        eta = timeFormat(csvLineValues[1][6])
        departureDate = dateFormat(csvLineValues[0])
        arrivalDate = setArrivalDate(etd, eta, departureDate)
        
        requestSlotType = lineFormValues["slot_type"]
        flightNumber = lineFormValues["flight_number"]
        requestedDate =  new Date(lineFormValues["date"])
        requestedTime = lineFormValues["time"]
        aircraftReg = lineFormValues["reg"]

        requestedAirport = airportRequest(originAirport, arrivalAirport, requestSlotType)

        let weekDayFormat = (week_date_raw) =>{
            let week_date = ""

            if (week_date_raw === 0) {
                week_date = "0000007"
            } else if (week_date_raw === 1) {
                week_date = "1000000"
            } else if (week_date_raw === 2){
                week_date = "0200000"
            } else if (week_date_raw === 3){
                week_date = "0030000"
            } else if (week_date_raw === 4){
                week_date = "0004000"
            } else if (week_date_raw === 5) {
                week_date = "0000500"
            } else if (week_date_raw === 6) {
                week_date = "0000060"
            }

            return week_date
        }

        let changeArrival = () =>{
            let lineOne = "";
            let lineTwo = "";

            let monthsList = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
		    let month_original = monthsList[arrivalDate.getMonth()]
		    let day_original = arrivalDate.getDate().toString()
            if (day_original.length == 1) {
                day_original = 0 + day_original;
            }
            let month_requested = monthsList[requestedDate.getMonth()]
            let day_requested = requestedDate.getDate().toString();
            if (day_requested.length == 1) {
                day_requested = 0 + day_requested;
            }
            

            lineOne = lineOne + `C${flightNumber} ${day_original}${month_original}${day_original}${month_original} ${weekDayFormat(arrivalDate.getDay())} 18973H ${originAirport}${eta} J%0A`
            lineTwo = lineTwo + `R${flightNumber} ${day_requested}${month_requested}${day_requested}${month_requested} ${weekDayFormat(requestedDate.getDay())} 18973H ${originAirport}${requestedTime} J%0A`

            return lineOne + lineTwo
        }

        let changeDeparture = () =>{
            let lineOne = "";
            let lineTwo = "";

            let monthsList = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
		    let month_original = monthsList[departureDate.getMonth()]
		    let day_original = departureDate.getDate().toString()
            if (day_original.length == 1) {
                day_original = 0 + day_original;
            }
            let month_requested = monthsList[requestedDate.getMonth()]
            let day_requested = requestedDate.getDate().toString();
            if (day_requested.length == 1) {
                day_requested = 0 + day_requested;
            }
            

            lineOne = lineOne + `C ${flightNumber} ${day_original}${month_original}${day_original}${month_original} ${weekDayFormat(arrivalDate.getDay())} 18973H ${etd}${arrivalAirport} J%0A`
            lineTwo = lineTwo + `R ${flightNumber} ${day_requested}${month_requested}${day_requested}${month_requested} ${weekDayFormat(requestedDate.getDay())} 18973H ${requestedTime}${arrivalAirport} J%0A`

            return lineOne + lineTwo
        }

        let generateText = () => {
            let change;
            if (formValues[index]["slot_type"] == "ARRIVAL") {
                change = changeArrival()
            } else {
                change = changeDeparture()
            }
            let result = `SCR%0A${iata_season}%0A${todaysDate()}%0A${requestedAirport}%0A${change}%0ASI ${generateDelays(index)}`

            return result
        }

        return generateText()
    }

    let findFlight = (flightNo) => {
        for (let i = 5; i < values.length; i++) {
            if(values[i][1][2].replace(/\s/g, '') === flightNo.replace(/\s/g, '')){
                return i
            }
        }
        return false
    }


    
    const [flights, setFlights] = useState([])

    if(formValues.length === 1 && formValues[0]["request_type"] == ""){
        formValues.pop()
    }
    return (
        <div className='booking_back_container'>
            <div className='booking_top_container'>
                <div className='top_top_container'>
                <div className='booking_text_container'>
						<div className='booking_req_container'>
							<h2 className='req_slots_title'>Step 1 - Upload Files</h2>
						</div>
					</div>
                <div>
                    <div className='scr_line'>
                        <div className='scr_line_one'>
                            <p>Upload Callsign Report</p>
                        </div>
                        <input
                        type="file"
                        name="file"
                        accept=".csv"
                        onChange={changeHandler}
                        className="scr_line_two"
                        />
                    </div>
                    <div className='scr_line'>
                        <div className='scr_line_one'>
                            <p>Upload Delays Report</p>
                        </div>
                        <input
                        type="file"
                        name="file"
                        accept=".csv"
                        onChange={changeDelaysHandler}
                        className="scr_line_two"
                        />
                    </div>
                </div>
                <div className='button-section'>
                    <button className='button_active' onClick={() => upload_btn()}>Upload</button>
                </div>
            </div>
            {sections[0]? 
            (
                <div className='bot_bot_container'>
                    <div className='booking_text_container'>
                        <div className='booking_req_container'>
                            <h2 className='req_slots_title'>Step 2 - Request Slots</h2>
                        </div>
                        <div className='booking_btn_container'>
                            <button className="button_add" type="button" onClick={() => addSlot()}>Request New Slot</button>
                        </div>  
                    </div>
                    <form  id="forma" onSubmit={handleSubmit}> 
				    {formValues.map((element, index ) => (
					<div className="form-inline_pt" key={index}>
						<div className='form-small'>
							<label><br/>{element.request_type}</label>
						</div>
                        	<div className='form-each'>
							<label>Slot Type</label>
							<select name="slot_type" onChange={e => handleChange(index, e)} required>
								<option value="">Select One</option>
								<option value="ARRIVAL">Arrival</option>
								<option value="DEPARTURE">Departure</option>
							</select>
						</div>
						<div className='form-med'>
							<label>Flight No</label>
							<input type="text" name="flight_number" value={element.flight_number || ""} onChange={e => handleChange(index, e)} minLength="3" required/>
						</div>
						<div className='form-large'>
							<label>New A/D Date</label>
							<input type="date" name="date" value={element.date|| ""} onChange={e => handleChange(index, e)} required/>
						</div>
						<div className='form-each'>
							<label>New A/D Time</label>
							<input type="text" name="time" value={element.time || ""} onChange={e => handleChange(index, e)} maxLength="4" minLength="4" required/>
						</div>
                        <div className='form-large'>
							<label>A/C Reg</label>
							<input type="text" name="reg" value={element.reg || ""} onChange={e => handleChange(index, e)} required/>
						</div>
						{
							formValues[index]["request_type"] === "N" || formValues[index]["request_type"] == "D"?
							<button type="button"  className="btn_remove" onClick={() => removeFormFields(index)}> x </button>
							:null
						}
						{
							formValues[index]["request_type"] === "C"?
							<button type="button"  className="btn_remove" onClick={() => {removeFormFieldsC(index)}}> x </button>
							:null
						}
						</div>
					))}
					<div className="button-section">
						{
							formValues.length?
							<button className="button_active" type="submit">Genererate SCR's</button>
							: null
						}
					</div>
				</form>
            </div>
            ) : 
            (
                <div></div>
            )
            }
            
            {
                sections[1]?  (
                <div className='bot_bot_container'>
                    <div className='booking_text_container'>
						<div className='booking_req_container'>
							<h2 className='req_slots_title'>Step 3 - Send SCR's</h2>
						</div>
					</div>
                    <div>
                        {
                            flights.map((element, index) => (
                                <div className='scr_line'>
                                    <div className='scr_line_one_pt'>
                                        <p>{flights[index]}</p>   
                                    </div>
                                    <div className='scr_line_two_pt'>
                                        <button className="button_add" onClick={()=> {donwloadEmail(index)}} >Send Email</button>
                                    </div>
                                    <div className='scr_line_three_pt'>
                                        <button className='button_add' onClick={() => {copyToClipboard(index)}}>Copy Spreadsheet</button>
                                    </div>
                                </div>
                                ))
                            }
                    </div>
                </div>
                ) : (
                    <div>

                    </div>
                )
            }
        


             {/* FOR DEBUGGING ONLY
            <div>
                <button onClick={() => {console.log(parsedData[6])}}>Parsed Data</button>
                <button onClick={() => {console.log(tableRows[6])}}>Table Rows</button>
                <button onClick={() => {console.log(values)}}>Values Array</button>
            </div>
            */}
            
            {/*  FOR DEBUGGING ONLY
                <div>
                    <button onClick={() => {console.log(parsedDelay)}}>Parsed Data</button>
                    <button onClick={() => {console.log(delaysRows)}}>Table Rows</button>
                    <button onClick={() => {console.log(delaysValue)}}>Values Array</button>
                </div>
            */}
            </div>
        </div>  
    )
}
