import React from 'react';
import { useState, useEffect } from "react";
import BannerSearch from '../components/BannerSearch';
import SearchComponent from '../components/SearchComponent';
import { collection, getDocs, getDoc, doc, updateDoc, increment} from "firebase/firestore";

function Search() {

    return (
        <div>
            <div>
                <BannerSearch/>
            </div>
            <div>
                <SearchComponent/>
            </div>
        </div>
    );
}

export default Search;