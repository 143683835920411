import React from 'react';
import Banner from '../components/Banner';
import Booking from '../components/Booking';
import HomeBanner from '../components/HomeBanner';

function Home() {
    return (
        <div>
            <HomeBanner/>
        </div>

    );
}

export default Home;