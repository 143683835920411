import React from 'react'
import "../styles/homebanner.css"
import "../styles/search.css"
import banner from "../assets/terminal.jpg"
import { useState, useEffect } from "react";
import BannerSearch from '../components/BannerSearch';
import { collection, getDocs, getDoc, doc, updateDoc, increment} from "firebase/firestore";
import {deleteDoc } from "firebase/firestore";
import {db} from "../firebase"
import EachSearch from './EachSearch';
import Lottie from "lottie-react";
import loading_a from "../assets/loading.json";
import ViewAirport from './ViewAirport';
import EditAirport from './EditAirport';
import { useNavigate } from "react-router-dom";
import Addirport from './NewAirport';


export default function SearchComponent() {

    const [allCourses, setAllCourses] = useState([]);
    const [searchField, setSearchField] = useState("");
    const [loading, setLoading] = useState(true);
    const [pages, setPages] = useState(1)
    const [indexElement, setIndexElement] = useState(0)
    const [results, setResults] = useState()
    const  [displayList, setDisplayList] = useState(true)
    const [filterBar, setFilterBar] = useState(true)
    const [viewAirport, setViewAirport] = useState(false)
    const [editAirport, setEditAirport] = useState(false)
    const [addAirport, setAddAirport] = useState(false)

    const [airportDetails, setAirportDetails] = useState({airport: "", country: "", summer:"", winter:"", gcr:"", ppr:"", info:"", emails:"", ga:""})
    
    const getData = async () => {
        const recentsCol = collection(db, "airports");
        const recentsSnapshot = await getDocs(recentsCol);
        setLoading(false)
        const recentsList = recentsSnapshot.docs.map(doc => doc.data());

  
        setAllCourses(recentsList);
    };

    useEffect(() => {
        getData();
    }, []);

    

    let page_per = 10

    const getIATA = async (iata) => {
        if (iata.length == 0){
            setLoading(true)
            getData();
            setDisplayList(true)

        } else {
            setLoading(true)
            const docRef = doc(db, "airports", iata);
            const docSnap = await getDoc(docRef);
            let recentsList 
            setLoading(false)
            if (docSnap.exists()) {
               recentsList = docSnap.data()
            } else {
               recentsList = false
    
            }
            setResults(recentsList)
            setDisplayList(false)
            console.log(results)
        }
    };



    const pagesNext = () => {
        if((indexElement + page_per) < allCourses.length){
            setPages(pages + 1)
            setIndexElement(pages * page_per)
        }
    }

    const pagesPrev = () => {
        if (pages != 1) {
            setPages(pages - 1)
            setIndexElement(indexElement - page_per)
        }
    }


    //Btn function to view airport details
    const btnView = (index) => {
        let localGCR = "No";
        let localPPR = "No"
        let localInfo = index.info


        if (index.gcr == true){
            localGCR = "Yes"
        } 

        if (index.ppr == true) {
            localPPR = "Yes"
        }

        if (localInfo.length == 0){
            localInfo = "No airport details"
        }
        setAirportDetails({
            airport: index["airport"], 
            country: index["country"], 
            summer: index["summer"],
            winter: index["winter"], 
            gcr: localGCR, 
            ppr: localPPR, 
            info: localInfo, 
            emails: index["emails"].join("; "),
            ga: index["ga"].join("; ")
        })
        setViewAirport(true);
        setDisplayList(false)
        setFilterBar(false)
        console.log("ola")
    }

    function backFunction ()  {
        setViewAirport(false);
        setDisplayList(true)
        setFilterBar(true) 
        getIATA(searchField); 
    }

    
    function backFunctionEdit ()  {
        setEditAirport(false);
        setDisplayList(true)
        setFilterBar(true) 
        getIATA(searchField); 
    }

    const addAirportBtn = () => {
        setAddAirport(true)
        setDisplayList(false)
        setFilterBar(false)
    }

    const deleteByRef = async (input) =>{
        await deleteDoc(doc(db, "airports", input));
        handleReload()
    }

    const navigate = useNavigate();

	let handleReload = () =>{
		navigate(0);
	}

    const btnDelete = (index) => {
        let confirmAction = window.confirm(`Are you sure you want to delete ${index["airport"]}?`);

        if (confirmAction) {
            deleteByRef(index["airport"])
          } else {
          }
    }

    const btnEdit = (index) => {
        let localGCR = "No";
        let localPPR = "No"
        let localInfo = index.info


        if (index.gcr == true){
            localGCR = "Yes"
        } 

        if (index.ppr == true) {
            localPPR = "Yes"
        }

        if (localInfo.length == 0){
            localInfo = "No airport details"
        }
        setAirportDetails({
            airport: index["airport"], 
            country: index["country"], 
            summer: index["summer"],
            winter: index["winter"], 
            gcr: localGCR, 
            ppr: localPPR, 
            info: localInfo, 
            emails: index["emails"].join("; "),
            ga: index["ga"].join("; ")
        })
        setEditAirport(true);
        setDisplayList(false)
        setFilterBar(false)
        console.log("ola")
    }


    return (
    <div className='booking_back_container'>
        <div className='booking_top_container'>
            <div className='top_top_container'>
                    <div className='booking_text_container'>
                        <div className='search_container'>
                           
                            {
                                filterBar? 
                                <div>
                                    <div className="search_form">
                                    <form className='search_form_one' onSubmit={(e) => {
                                        e.preventDefault();  // Prevent default form submission
                                        getIATA(searchField);  // Call the getIATA function with searchField value
                                    }}>
                                        <div>
                                            <input className="search_bar" type="text" name="iata" value={searchField} onChange={e => setSearchField(e.target.value.toUpperCase())} minLength="3" maxLength="3" placeholder='Search for airport IATA code'/>
                                        </div>
                                    </form>
                                        <div className='search_form_two'>
                                                <button className="button_search" onClick={async () => getIATA(searchField)}>Search</button>
                                        </div>
                                    </div>
                                    <div className='search_col_bar'>
                                    <div className='search_col_small'>
                                        <p><strong>Airport</strong></p>
                                    </div>
                                    <div className='search_col_small'>
                                        <p><strong>Country</strong></p>
                                    </div>
                                    <div className='search_col_small'>
                                        <p><strong>Summer Level</strong></p>
                                    </div>
                                    <div className='search_col_small'>
                                        <p><strong>Winter Level</strong></p>
                                    </div>
                                    <div className="search_col_big">
                                        <button className='button_orange' onClick={() => addAirportBtn()}>Add Airport</button>
                                    </div>
                                    </div>
                                </div>
                                : null
                            }

                            <div>
                                {
                                    (() => {
                                        if(loading == true) {
                                                return (
                                                    <div className='search_animation'>
                                                        <Lottie animationData={loading_a} loop={true} />;
                                                    </div>
                                                )
                                            }  else if (loading === false && addAirport == true) {
                                                return(
                                                <Addirport/>
                                                )
                                            } else if (loading === false && viewAirport == true) {
                                                return(
                                                    <div>
                                                        <div className='airport_back'>
                                                            <a href="#" onClick={(e) => { e.preventDefault(); backFunction(); }}>Back</a>
                                                        </div>
                                                        <ViewAirport 
                                                        airport={airportDetails.airport} 
                                                        country={airportDetails.country}
                                                        summer={airportDetails.summer}
                                                        winter={airportDetails.winter}
                                                        gcr={airportDetails.gcr}
                                                        ppr={airportDetails.ppr}
                                                        info={airportDetails.info}
                                                        emails={airportDetails.emails}
                                                        ga={airportDetails.ga}
                                                        />
                                                    </div>
        
                                                )
                                            } else if (loading === false && editAirport == true) {
                                                return(
                                                    <div>
                                                        <a href="#" onClick={(e) => { e.preventDefault(); backFunctionEdit(); }}>Back</a>
                                                <EditAirport
                                                airport={airportDetails.airport} 
                                                country={airportDetails.country}
                                                summer={airportDetails.summer}
                                                winter={airportDetails.winter}
                                                gcr={airportDetails.gcr}
                                                ppr={airportDetails.ppr}
                                                info={airportDetails.info}
                                                emails={airportDetails.emails}
                                                ga={airportDetails.ga}
                                                />
                                                    </div>
                                                    
                                                )
                                            } else if (loading === false && displayList == true) {
                                                return (
                                                    <div> 
                                                    {allCourses.slice(indexElement, page_per + indexElement).map((index, element) => (
                                                        <EachSearch 
                                                        airport={index["airport"]}
                                                        country={index["country"]}
                                                        summer={index["summer"]}
                                                        winter={index["winter"]}
                                                        viewFunction={() =>btnView(index)}
                                                        editFunction={() => btnEdit(index)}
                                                        deleteFunction={() => btnDelete (index)}
                                                        />
                                                    ))}
                                                        <div className='search_pages'>
                                                            <div className='search_comp'>
                                                                <button className="button_add" onClick={() => pagesPrev()}>Previous</button>
                                                            </div>
                                                            <div className='search_comp_a'>
                                                                <p>{pages}</p>
                                                            </div>
                                                            <div className='search_comp'>
                                                                <button className="button_add" onClick={() => pagesNext()}>Next</button>  
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                )
                                            } else if (results != false) {
                                                return (
                                                    <EachSearch 
                                                        airport={results["airport"]}
                                                        country={results["country"]}
                                                        summer={results["summer"]}
                                                        winter={results["winter"]}
                                                        viewFunction={() =>btnView(results)}
                                                        editFunction={() => btnEdit(results)}
                                                        deleteFunction={() => btnDelete (results)}
                                                        />
                                                )
                                            } else if (results == false) {
                                                return (
                                                    <div>
                                                        <p>No Results found</p>
                                                    </div>
                                                )
                                            } 
                                    })()  
                                }  
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
  )
}