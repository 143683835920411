import React from 'react'
import "../styles/banner.css"
import banner from "../assets/3.jpg"

export default function BannerNight() {
  return (
    <div className='banner_container'>
        <img className="banner_img" src={banner}/>
        <h1 className="banner_text">PT Night Slots</h1>
    </div>
  )
}
