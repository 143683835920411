import React from 'react';
import Booking from '../components/Booking';
import BannerPlannedDiversions from '../components/BannerPlannedDiversions';
import PlannedDiv from '../components/PlannedDiv';

function PlannedDiversions() {
    return (
        <div>
            <div>
                <BannerPlannedDiversions/>
            </div>
            <div>
                <PlannedDiv/>
            </div>
        </div>
    );
}

export default PlannedDiversions;