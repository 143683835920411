import React from 'react';
import PTSlots from '../components/PTSlots';
import BannerNight from '../components/BannerNight';

function PtNightSlots() {
    return (
        <div>
            <div>
                <BannerNight/>
            </div>
            <div>
                <PTSlots/>
            </div>
        </div>

    );
}

export default PtNightSlots;