import React from 'react'
import "../styles/banner.css"
import banner from "../assets/4.jpg"

export default function BannerPlannedDiversions() {
  return (
    <div className='banner_container'>
        <img className="banner_img" src={banner}/>
        <h1 className="banner_text">Planned Diversions</h1>
    </div>
  )
}
