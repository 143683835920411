import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blogs from './pages/Scr';
import Home from './pages/Home';
import Layout from './pages/Layout';
import Scr from './pages/Scr';
import PtNightSlots from './pages/Contact';
import Search from './pages/Search';
import PlannedDiversions from './pages/PlannedDiversions';

export default function App() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="scr" element={<Scr />} />
            <Route path="ptnightslots" element={<PtNightSlots/>} />
            <Route path="search" element={<Search/>} />
            <Route path="planned-diversions" element={<PlannedDiversions/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
  
  ReactDOM.render(<App />, document.getElementById("root"));
