import React from 'react'
import "../styles/homebanner.css"
import "../styles/viewairport.css"
import banner from "../assets/terminal.jpg"
import { useState, useEffect } from "react";
import { doc, updateDoc } from "firebase/firestore";
import {db} from "../firebase"

export default function EditAirport (props) {
    let localGCR
    let localPPR 


    if (props.gcr == "Yes"){
        localGCR = "true";
    } else {
        localGCR = "false";
    }

    if (props.ppr == "Yes") {
        localPPR = "true";
    } else {
        localPPR = "false";
    }
    const [fields, setFields] = useState({airport: props.airport, country: props.country, summer:props.summer, winter:props.winter, gcr:localGCR, ppr:localPPR, info: props.info, emails: props.emails, ga:props.ga});

    const handleChange = (field, input) =>{
        let result = {airport: fields.airport, country: fields.country, summer:fields.summer, winter:fields.winter, gcr:fields.gcr, ppr:fields.ppr, info: fields.info, emails: fields.emails, ga:fields.ga};

        result[field] = input.target.value;
        setFields(result)
    }

    const changeDetails = async () => {
        const changeRef = doc(db, "airports", fields.airport);

        let tempGCR, tempPPR;
        if (fields.gcr == "true") {
            tempGCR = true
        } else {
            tempGCR = false
        }

        if (fields.ppr == "true") {
            tempPPR = true
        } else {
            tempPPR = false
        }

        let result = {
            airport: fields.airport,
            country: fields.country,
            emails: fields.emails.split("; "),
            ga: fields.ga.split("; "),
            gcr: tempGCR,
            info: fields.info,
            ppr: tempPPR,
            summer: fields.summer,
            winter: fields.winter
        }

        await updateDoc(changeRef, result);

        alert("Changes Saved");
    }
    
    return (
        <div className='container_airport'>
            <div className='airport_back'>
                <h1>Edit airport</h1>
            </div>
            <div>
                <form>
                    <div className='scr_line'>  
                        <div className='scr_line_one'>
                            <label>Airport</label>
                        </div>
                        <div className='scr_line_two'>
                            <p>{fields.airport}</p>
                        </div>
                    </div>
                    <div className='scr_line'>
                        <div className='scr_line_one'>
                            <label>Country</label>
                        </div>
                        <div className='scr_line_two'>
                            <input type="text" name="country" value={fields.country} onChange={e => handleChange("country", e)}/>
                        </div>
                    </div>
                    <div className='scr_line'>
                        <div className='scr_line_one'>
                            <label>Summer Level</label>
                        </div>
                        <div className='scr_line_two'>
                            <select name="summer"  value={fields.summer} onChange={e => handleChange("summer", e)}>
                                    <option value="none">Select One</option>
                                    <option value="3">Level 3</option>
                                    <option value="2">Level 2</option>
                                    <option value="0">None</option>
                            </select>
                        </div>
                    </div>
                    <div className='scr_line'>
                        <div className='scr_line_one'>
                            <label>Winter Level</label>
                        </div>
                        <div className='scr_line_two'>
                            <select name="winter" value={fields.winter} onChange={e => handleChange("winter", e)}>
                                    <option value="none">Select One</option>
                                    <option value="3">Level 3</option>
                                    <option value="2">Level 2</option>
                                    <option value="0">None</option>
                            </select>
                        </div>
                    </div>
                    <div className='scr_line'>
                        <div className='scr_line_one'>
                            <label>GCR</label>
                        </div>
                        <div className='scr_line_two'>
                            <select name="gcr" value={fields.gcr} onChange={e => handleChange("gcr", e)}>
                                    <option value="none">Select One</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                            </select>
                        </div>
                    </div>
                    <div className='scr_line'>
                        <div className='scr_line_one'>
                            <label>PPR</label>
                        </div>
                        <div className='scr_line_two'>
                            <select name="ppr" value={fields.ppr} onChange={e => handleChange("ppr", e)}>
                                    <option value="none">Select One</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                            </select>
                        </div>
                    </div>
                    <br></br>
                    <div className='scr_line'>
                        <div className='scr_line_one'>
                            <label>Airport Info</label>
                        </div>
                        <div className='scr_line_two'>
                            <textarea name="info" rows="4" cols="50" value={fields.info} onChange={e => handleChange("info", e)}>
                            </textarea>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className='scr_line'>
                        <div className='scr_line_one'>
                            <label>Emails</label>
                        </div>
                        <div className='scr_line_two'>
                            <textarea name="emails" rows="4" cols="50" value={fields.emails} onChange={e => handleChange("emails", e)}>
                            </textarea>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className='scr_line'>
                        <div className='scr_line_one'>
                            <label>GA Emails</label>
                        </div>
                        <div className='scr_line_two'>
                            <textarea name="ga" rows="4" cols="50" value={fields.ga} onChange={e => handleChange("ga", e)}>
                            </textarea>
                        </div>
                    </div>
                </form>
                <div className="button-section">
                        <button className="button_add" type="submit" onClick={() => changeDetails()}>Save Changes</button>
                    </div>
            </div>
        </div>
  )
}
