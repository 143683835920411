import React from "react";
import { BrowserRouter, Route, Link, NavLink } from "react-router-dom";
import logo from "../assets/logo-2.png"
import "../styles/topbar.css"

function Navbar() {
  return (
    <div className="topbar_container">
        <div className="container_logo">
            <Link to="/">
                <img className="topbar_logo" src={logo}/>
            </Link>
        </div>
        <div className="container_menu">
            <nav>
                <ul className="menu_ul">
                    <li className="menu_li">
                    <   NavLink className={({ isActive }) => isActive ? "menu_link_active" : "menu_link"} to="" end>Home</NavLink>
                    </li>
                    <li className="menu_li">
                        <NavLink className={({ isActive }) => isActive ? "menu_link_active" : "menu_link"} to="/scr">Create SCR's</NavLink>
                    </li>
                    <li className="menu_li"> 
                        <NavLink className={({ isActive }) => isActive ? "menu_link_active" : "menu_link"} to="/ptnightslots">PT Night Slots</NavLink>
                    </li>
                    <li className="menu_li"> 
                        <NavLink className={({ isActive }) => isActive ? "menu_link_active" : "menu_link"} to="/search">Search</NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
  );
}

export default Navbar;