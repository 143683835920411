import React from 'react'
import "../styles/homebanner.css"
import banner from "../assets/terminal.jpeg"

export default function HomeBanner() {
  return (
    <div className='home_banner_container'>
        <div className="home_banner_one">
            <h1 className='home_banner_title'>
               
            </h1>
        </div>
    </div>
  )
}
