import React from 'react';
import Banner from '../components/Banner';
import Booking from '../components/Booking';

function Scr() {
    return (
        <div>
            <div>
                <Banner/>
            </div>
            <div>
                <Booking/>
            </div>
        </div>
    );
}

export default Scr;