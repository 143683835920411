import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAhKM2_E-JCPnafr7TOtbFtiQFXASyY1oU",
    authDomain: "slotdeskapp.firebaseapp.com",
    projectId: "slotdeskapp",
    storageBucket: "slotdeskapp.appspot.com",
    messagingSenderId: "52977039018",
    appId: "1:52977039018:web:538f05535cbec0eeefc2d3"
};

const app = initializeApp(firebaseConfig);
//const auth = getAuth();
const db = getFirestore();
//const database = getDatabase(app);

//export { auth, db, database, analytics};



export { app, db};