import React from 'react'
import "../styles/homebanner.css"
import "../styles/viewairport.css"
import banner from "../assets/terminal.jpg"


export default function ViewAirport (props) {
  
    return (
    <div className='container_airport'>
        <div className='container_title_airport'>
            <h1>{props.airport}</h1>
            <p>{props.country}</p>
        </div>
        <div className='container_table_airport'>
            <div className='container_table_airport_each'>
                <p style={{color : "#f59b42"}}>Summer</p>
                <h2>Level {props.summer}</h2>
            </div>
            <div className='container_table_airport_each'>
                <p style={{color : "#6a6ff7"}}>Winter</p>
                <h2>Level {props.winter}</h2>
            </div>
            <div className='container_table_airport_each'>
                <p style={{color : "#f77f6a"}}>GCR</p>
                <h2>{props.gcr}</h2>
            </div>
            <div className='container_table_airport_each'>
                <p style={{color : "#9fde97"}}>PPR</p>
                <h2>{props.ppr}</h2>
            </div>
        </div>
        <div>
            <br></br>
            <h3>Airport Info</h3>
            <div>
                {props.info.split('\n').map((str, index, array) => 
                index === array.length - 1 ? str : <>
                    {str}
                    <br />
                </>
            )}
        </div>

            <br></br>
        </div>
        <div>
            <h3>Email Contacts</h3>
            <p>{props.emails}</p>
            <br></br>
        </div>
        <div>
            <h3>General Aviation Emails</h3>
            <p>{props.ga}</p>
            <br></br>
        </div>
    </div>
  )
}
