import React from 'react'
import "../styles/banner.css"
import banner from "../assets/1.jpg"

export default function BannerSearch() {
  return (
    <div className='banner_container'>
        <img className="banner_img" src={banner}/>
        <h1 className="banner_text">Airport Search</h1>
    </div>
  )
}
